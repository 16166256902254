import React from 'react'

import './../styles/pageSpecific/services.scss'
import GeneralPageLayout from '../components/Layouts/GeneralPage'
import MainTextBlock from '../components/UI/MainTextBlock/MainTextBlock'
import SectionContainer from '../components/UI/Containers/SectionContainer'
import SectionHeader from '../components/UI/SectionHeader/SectionHeader'
import SplitTwoToneContainer from './../components/UI/Containers/SplitTwoToneContainer'
import CapabilityGraphic from '../components/UI/Capabilities/CapabilityGraphic/CapabilityGraphic'

import ExpertCapabilities from '../components/UI/Capabilities/ExpertCapabilities/ExpertCapabilities'
import ServiceIcon from './../images/icons/capabilities/Security.inline.svg'
import SecurityGraphic from './../images/capabilities/Security.png'

const SecurityPage = () => {
  const pageInfo = {
    pageType: 'interior',
    title1: '',
    title2: 'Security',
    breadcrumbs: ['Capabilities'],
    ctaButton: false,
    ctaButtonData: {
      text: '',
      url: '',
    },
  }

  return (
    <GeneralPageLayout pageData={pageInfo}>
      <div className="content">
        {/* Capabilities Video */}
        <SectionContainer
          id="serviceVideo"
          color="white"
          type="pressedEdgeRight"
          classes={['inlineChildren_half', 'capabilitySection']}
        >
          <section>
            <SectionHeader color="navy" alignment="left" size="med">
              Holistically Protecting <span>Our Property</span>
            </SectionHeader>
            <MainTextBlock>
              <p>
                We provide comprehensive, integrated security protection for
                government agencies and industry partners. Using advanced
                technology, approved processes, and detailed procedures, our
                highly skilled personnel provide systematic protection of
                physical assets, intellectual property, and malicious intrusion
                of computer-stored information.
              </p>
            </MainTextBlock>
          </section>
          <CapabilityGraphic
            src={SecurityGraphic}
            title="Computer security with locked display"
          />
        </SectionContainer>

        {/* Why Us */}
        <SplitTwoToneContainer id="" classes={['hugMiddleLine']}>
          <section>
            <h3>
              <span>
                Our security experts are equipped to handle our customer’s
                mission-essential needs.{' '}
              </span>
            </h3>
          </section>
          <section>
            <h3>
              From Physical to Operational security, our team provides a
              comprehensive full-spectrum approach.{' '}
            </h3>
          </section>
        </SplitTwoToneContainer>

        {/* Expert Capabilities */}
        <SectionContainer id="" color="white" type="pressedEdgeLeft">
          <ExpertCapabilities
            serviceName="Security"
            ServiceIcon={ServiceIcon}
          />
        </SectionContainer>
      </div>
    </GeneralPageLayout>
  )
}

export default SecurityPage
