import React from 'react'
import * as styles from './CapabilityGraphic.module.scss'


const CapabilityGraphic = ({src, title}) => {
    return(
        <section className={styles.capabilityGraphic}>
            <img 
                src={src}
                alt={title}
                width='100%'
                height='100%'
                loading='lazy'/>
        </section>
    )
}


export default CapabilityGraphic